.container {
  min-height: 100%;
}

.breadcrumb-wrapper {
  flex: 1 1;
  text-align: center;
  display: flex;
}

.breadcrumb-wrapper a::after {
  display: none;
}

/* BEGIN METADATA */

.metadata-desktop {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.metadata-desktop .product-image {
  width: 165px;
  height: 165px;
}

.product-image img {
  height: 100%;
  width: 100%;
}

.metadata-mobile {
  flex: 1;
  display: flex;
  align-items: center;
}

.metadata-mobile .metadata-list {
  height: auto;
}

.metadata-mobile .product-image {
  min-width: 100px;
  width: 100px;
  max-width: 100px;
}

.metadata-mobile .metadata-item {
  font-size: .75rem;
}

.metadata-mobile .metadata-item label {
  font-size: .75rem;
}

.metadata-mobile .metadata-list h6 {
  font-size: 1rem;
  margin-bottom: 0px;
}

.metadata-list {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.metadata-item {
  display: flex;
  color: #757575;
}

.metadata-item label {
  margin-bottom: 0px;
}

.metadata-item a {
  text-decoration: none;
}

.metadata-item i {
  margin-right: 10px;
}

/* END METADATA */

.tabs {
  max-width: 100%;
  flex: 1;
}

/* README Headings are scaled to about 65% of original size */

.readme-area h1 {
  font-size: 2rem;
}

.readme-area h2 {
  font-size: 1.5rem;
}

.readme-area h3 {
  font-size: 1.15rem;
}

.readme-area h4 {
  font-size: 1rem;
}

.readme-area h5 {
  font-size: .85rem;
}

.readme-area h6 {
  font-size: .75rem;
}

.readme-area pre {
  overflow-y: hidden;
}

/* END README HEADINGS */

.title-area {
  min-height: 165px;
  flex: 1;
}

.title-area h4 {
  margin-bottom: 0px;
}

.title-area label {
  color: #757575;
  /* MWF_GRAY_500 */
}

.mobile-product-image-wrapper {
  display: flex;
  justify-content: center;
}

/* This will only happen in mobile mode */

.mobile-product-image-wrapper .product-image {
  width: 150px;
}

.no-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%
}

.tag-table-spinner {
  display: flex;
  justify-content: center;
  min-height: 25vh;
  align-items: center;
}

.no-readme {
  font-style: italic;
  color: #757575;
  /*MWF_GRAY_500*/
}

.disclaimer-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}

.disclaimer-box {
  padding: 10px;
  background-color: #505050;
  color: #ffffff;
  gap: 10px;
}
