.message {
    font-weight: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
}

.message>h2 {
    font-weight: 100;
}
