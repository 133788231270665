.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
}

.no-content {
  display: flex;
  height: 100%;
  min-height: 60vh;
  min-width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.sort-wrapper {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
}

.dropdown-wrapper {
  flex-basis: 300px;
}

.remove-filter-container
{
  display: flex;
  flex-wrap: wrap;
}

.remove-filter-button
{
  justify-content: space-around;
  margin-right: 5px;
}