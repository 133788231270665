.skip-to-main {
  justify-content: center;
  align-items: center;
  display: flex;
}

.skip-to-main>button {
  position: absolute;
  top: -1000px;
}

.skip-to-main>button:focus {
  position: relative;
  margin: 50px;
  top: auto;
  top: unset;
}

.nav {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0078D4;
  color: white;
  margin: 0px;
}

.navInternal {
  background-color: black;
}

.nav .left {
  display: flex;
  flex: 0 1;
  -ms-flex: 1;
}

.nav .right {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.grid-layout {
  width: 100%;
  margin: 0px;
}

.logo-spacer {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: white solid .15rem;
  user-select: none;
  margin-left: 1rem;
  margin-right: .5rem;
  height: 50%;
  align-self: center;
}

.logo-text {
  white-space: nowrap;
  font-size: 18px;
  background-color: transparent;
}

.logo-text:hover {
  text-decoration: underline;
}

.logo-text.hidden {
  display: none !important;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: baseline;
  cursor: pointer;
}

.button-wrapper:hover {
  background-color: 	#0067B8;
}

.button-wrapper-internal:hover {
  background-color:#262626;
}

.button-wrapper label {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: .5rem;
  cursor: pointer;
}

.button-wrapper .search-button {
  color: white !important;
  align-self: center;
}

.button-wrapper .search-button:hover {
  background-color: transparent;
}

.search-box {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-box.hidden {
  display: none !important;
}

.search-box div {
  flex: 1;
  display: flex;
}

.search-box input {
  width: 100% !important;
}

.cancel-button.hidden {
  display: none;
}

.right {
  font-weight: 400;
}

.tab-link {
  color: white;
  text-decoration: none;
  font-size: 13px;
}

.tab-link:hover {
  text-decoration: underline;
}

.tab-link-container {
  transform: translate(0px, 5px);
}

.dropdown-container {
  width: 100%;
  display: flex;
}

.dropdown-container>div {
  flex: 1;
}

.dropdown-container>div>button {
  color: white !important;
  background-color: #0078D4 !important;
  width: 100%;
  border-radius: 0px;
}

.dropdown-container>div>button:focus {
  background-color: #00477e !important;
}

.dropdown-container>div>ul {
  width: 100%;
  border-radius: 0px;
}

.search-box input::placeholder {
  opacity: .6;
}

/* SCREEN SPECIFIC STYLES */

/* SM  or <*/

@media (max-width: 860px) {
  .logo-img-link {
    background-position: center;
    background-repeat: no-repeat;
    outline-color: white;
    color: white;
    background-size: contain;
    width: 30px;
  }

  .logo-img-link.hidden {
    display: none;
  }
}

/* MD or >*/

@media (min-width: 860px) {
  .nav {
    margin-bottom: 1rem;
  }

  .logo-img-link {
    min-width: 129px;
    width: 129px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 112px;
    outline-color: white;
    color: white;
  }
}