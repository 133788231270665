.pagination-control {
  flex: 1;
  display: flex;
  justify-content: center;
}

.pagination-control.flex-start {
  justify-content: flex-start;
  
}

.pagination-control.flex-end {
  justify-content: flex-end;
}