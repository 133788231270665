.container {
  background: #F3F2F1;
  border: 1px solid #A19F9D;
  box-sizing: border-box;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  padding: 0px 1ch;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 15px;
  line-height: 24px;
  color: #323130;
  display: inline-block;
}

.copy-button {
  height: unset;
  width: unset;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  outline-color: black;
  color: black;
  text-align: center;
  margin-left: 1ch;
}

.copy-button:hover {
  color: rgb(0, 120, 212);
}

.copy-icon {
  font-size: 13px;
}