.container {
  display: flex;
}

.sign-in-button {
  margin-left: 1rem;
  background-color: #0078d4;
}

.sign-out-button {
  margin-top: 1rem;
}

.control-container {
  position: relative;
}

.display-name {
  margin-right: .5rem;
}

.user-button {
  background-color: transparent;
  background-color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0px 9px;
}

.user-button:hover {
  background-color: transparent !important;
  background-color: unset !important;
  box-shadow: none;
  background-image: none;
}

.user-button:hover .user-image.none {
  font-size: 1.1rem;
  border: none;
}

.user-button .user-image.none {
  border: #2f2f2f 1.5px solid;
}

.user-button:hover .user-image {
  border: none;
}

.user-button .user-image {
  border: #757575 1.5px solid;
  width: 2.35rem;
  height: 2.35rem;
}

.user-image {
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
}

.user-image.none {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background-color: #3aa0fa;
}

.user-image.big {
  width: 4rem;
  height: 4rem;
}

.user-image.big.none {
  font-size: 2rem;
}

.info-area {
  position: absolute;
  padding: 1rem;
  z-index: 100;
  right: 0;
  background-color: white;
  box-shadow: 0 .375rem .875rem 0 rgba(0, 0, 0, .13), 0 .0625rem .25rem 0 rgba(0, 0, 0, .11) !important;
}

.info-area.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .2s linear;
}

.info-area.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .2s, opacity .2s linear;
}

.info-text {
  color: black;
  margin-left: 1rem;
  flex: 1;
  -ms-flex: 1 0 auto;
}

.info-data-row {
  align-items: center;
}