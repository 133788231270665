.collapsible-row {
  cursor: pointer;
}

.collapsible-row>:first-child::before {
  font-family: MWF-MORAY-MDL2;
  content: "";
  font-size: .8125em;
  margin-right: 10px;
  transform: rotate(-90deg);
  transition: transform .25s;
  display: inline-block;
}

.collapsible-row.open>:first-child::before {
  transform: unset;
}

.collapsible-row>td {
  border: none;
}

.content-row>td {
  padding: 0px;
  margin-left: 4rem !important;
}

.content {
  padding: 1rem 0rem;
}

.header-container {
  display: flex;
  align-items: center;
}

.header-icon {
  margin-left: 1rem;
  display: flex;
}

.header-icon.hidden {
  opacity: 0;
}

.no-content {
  display: flex;
  justify-content: center;
  font-style: italic;
  color: #a2a2a2;
  /*GRAY_300*/
}