.container {
  padding: 2px 12px;
  cursor: pointer;
  height: 260px;
  width: 220px;
}

.title {
  font-size: 14px;
  margin-bottom: 0px;
}

.image {
  min-width: 36px;
  height:100%;
}

.description {
  font-size: 13px;
  height: 3em;
}

.info {
  padding-left: 8px;
}

.info section {
  font-size: 12px;
  font-weight: 400;
  color: #605E5C;
}

.header {
  display: flex;
  flex: content;
  align-items: center;
  height: fit-content;
}

.hide-overflow {
  overflow: hidden;
}

.no-description {
  font-style: italic;
  color: #747474;
  /*GRAY_300*/
}

.call-to-action {
  font-size: 13px;
  color: #0078D4;
  text-decoration: none;
  position: relative;
}

.call-to-action:hover {
  text-decoration: underline;
}

.container .call-to-action {
  margin-bottom: 0px;
}

.arch-bubble {
  border-radius: 30px;
  background-color: #0067B8;
  color: white;
  font-size: .7rem;
  padding: 2px 8px;
  white-space: nowrap;
}

.category-bubble {
  border-radius: 30px;
  color: white;
  background-color: #0078D4;
  font-size: .7rem;
    padding: 2px 8px;
    white-space: nowrap;
  }
  
  .category-bubble-purple {
    border-radius: 30px;
  color: white;
  background-color: #6E5DB7;
  font-size: .7rem;
  padding: 2px 8px;
  white-space: nowrap;
}

.category-bubble-darkgrey {
  border-radius: 30px;
  color: white;
  background-color: #505050;
  font-size: .7rem;
  padding: 2px 8px;
  white-space: nowrap;
}

.arch-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.5em;
  padding-bottom: 0.25em;

}