.message {
    font-weight: 100, bold;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    min-height: 50vh;
}

.message>h3 {
    font-weight: 50, bold;
}

.message>p {
    font-weight: 100, bold;
}

.indent>p {
    padding-left: 20px;
}

.last_updated {
    padding-top: 20px;
    align-items: left;
}
