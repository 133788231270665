/* XS */

@media (min-width: 0px) {}

@media (max-width: 540px) {
  .desktop-only {
    display: none !important;
    visibility: hidden !important;
  }
}

/* SM */

@media (min-width: 540px) {}

@media (max-width: 860px) {
  .desktop-only {
    display: none !important;
    visibility: hidden !important;
  }
}

/* MD */

@media (min-width: 860px) {
  .mobile-only {
    display: none !important;
    visibility: hidden !important;
  }
}

/* LG */

@media (min-width: 1084px) {
  .mobile-only {
    display: none;
    visibility: hidden !important;
  }
}

/* XL */

@media (min-width: 1400px) {
  .mobile-only {
    display: none;
    visibility: hidden !important;
  }
}