.filter-header {
    display: flex;
    align-items: center;
}

.filter-header input::placeholder {
    opacity: .6;
  }

.supported-tags-box {
    margin-left: 25px;
}

.tabs {
  max-width: 100%;
  flex: 1;
}

.long-cell {
  max-width: 110px; 
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: none;
  padding: 0 20px;
  z-index: 2;
}

.showing-modal {
  overflow: hidden;
}

.scrollingContent {
  overflow: auto;
  height: '400px';
}

.showing-sbom{
  white-space: 'pre-wrap';
  overflow: 'auto';
  margin: '0';
} 

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
}

.artifact-btn
{
  background-Color: 'transparent'
}