.heading-container {
  display: flex;
  align-items: baseline;
}

.heading a{
  text-decoration: none;
  color: black;
}

.heading-link {
  opacity: 0;
  transition: opacity .25s ease-in-out;
  background-color: transparent;
}

.heading-link:hover {
  opacity: 1;
  background-color: transparent;
}

.heading-link:focus{
  opacity: 1;
  background-color: transparent;
}