.locale-picker button {
  padding-left: 2.5rem;
  background: transparent;
  color: #616161 !important;
  font-size: 11px;
  display: none;
}

.locale-picker button:hover {
  background: black;
  color: white !important;
  box-shadow: none;
}

.locale-picker button::before {
  transform: translateY(-5px);
  font-size: 18px;
}

/* There is a weird discrepancy in this range that makes it look weird. Hence the extra margin*/
@media (max-width: 1084px) and (min-width: 768px) {
  .locale-picker-container {
    margin-bottom: 11px;
  }
}

@media (max-width: 1084px) {
  .locale-picker-container {
    width: 100%;
  }
}

/* LG or bigger screen size */
@media (min-width: 1084px) {
  .locale-picker {
    transform: translateY(-30%);
    display: inline-block;
  }

  .locale-picker-container {
    height: 0px;
  }
}