html, body {
  height: 100%;
}

body {
  display: flex;
  flex-flow: column;
}

#root {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: content;
}

replace {
  display: none;
  visibility: hidden;
}

.productArea
{
  padding-left:1em;
  flex-basis: 0;
  flex-grow: 1;
}

.sortByLabel
{
  padding-right:0.5em;
}

.filterCol
{
  min-width: min-content;
}

.searchTotal
{
  padding-top: 1.5em;
  padding-right: 2em;
  padding-left: 1em;
}


.sortButton
{
  background-color:#f2f2f2;
  padding-top: 2px;
  padding-bottom: 2px;
}

.clearFiltersButton
{
  max-height: fit-content;
}